<template>
  <DashboardTemplate >
    <Home/>
    <About/>
    <Contact/>
  </DashboardTemplate>
</template>

<script>
import DashboardTemplate from '@/template/DashboardTemplate.vue'

import Home from '@/components/page/Home.vue'
import About from '@/components/page/About.vue'
import Contact from '@/components/page/Contact.vue'

export default {
  components: {
    DashboardTemplate,

    Home,
    About,
    Contact
  }
}
</script>
