var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"home"}},[_vm._m(0),_c('div',{staticClass:"community"},[_c('a',{staticClass:"community-text",attrs:{"href":"/community/1"}},[_vm._v("ศูนย์วัฒนธรรมไทลื้อวัดหย่วน ตำบลหย่วน อำเภอเชียงคำ จังหวัดพะเยา")]),_c('div',{staticClass:"community-carousel"},[_c('Carousel',{attrs:{"carousel":[
             require("../../assets/product/cp1-1.jpg"),
             require("../../assets/product/cp2-1.jpg"),
             require("../../assets/product/cp3-1.jpg"),
             require("../../assets/product/cp4-1.jpg"),
             require("../../assets/product/cp5-1.jpg")
           ]}})],1)]),_c('div',{staticClass:"community"},[_c('a',{staticClass:"community-text",attrs:{"href":"/product/ng1"}},[_vm._v("ชุมชนแม่อิง ตำบลแม่อิง อำเภอภูกามยาว จังหวัดพะเยา")]),_c('div',{staticClass:"community-carousel"},[_c('Carousel',{attrs:{"carousel":[
               require("../../assets/product/ng1-1.jpg") ]}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('a',{attrs:{"href":"/project/1"}},[_vm._v("โครงการทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์")])])}]

export { render, staticRenderFns }