<template>
   <div id="home">
        <div class="title">        
          <a href="/project/1">โครงการทุนวัฒนธรรมชุมชนสู่หัตถกรรมล้านนาสร้างสรรค์</a>
        </div>
        <div class="community">
          <a href="/community/1" class="community-text">ศูนย์วัฒนธรรมไทลื้อวัดหย่วน ตำบลหย่วน อำเภอเชียงคำ จังหวัดพะเยา</a>
          <div class="community-carousel">
            <Carousel :carousel="[
                require(`../../assets/product/cp1-1.jpg`),
                require(`../../assets/product/cp2-1.jpg`),
                require(`../../assets/product/cp3-1.jpg`),
                require(`../../assets/product/cp4-1.jpg`),
                require(`../../assets/product/cp5-1.jpg`)
              ]"
            />
          </div>
        </div>
        <div class="community">
            <a href="/product/ng1" class="community-text">ชุมชนแม่อิง ตำบลแม่อิง อำเภอภูกามยาว จังหวัดพะเยา</a>
            <div class="community-carousel">
              <Carousel :carousel="[
                  require(`../../assets/product/ng1-1.jpg`),
                ]"/>
            </div>  
        </div>  
        
      </div>
</template>

<script>
import Carousel from './Carousel.vue'

export default {
   components: {
     Carousel
   }
}
</script>


<style lang="scss" scoped>
@import "../../styles/element-variables.scss";
#home{
  padding: 40px 10vw 0 10vw;
  .title{
      text-align: center;
      padding: 20px 0;
    a{
      font-size: 30px;
      margin: 40px 0 50px 0;
      letter-spacing: 0;
      cursor: pointer;
      color:$--color-text-primary;
      text-decoration:none;
    }
     a:hover {
      color:$--color-primary;
    }
  }
 
  .community{
    margin-bottom: 60px ;
    a.community-text {
      font-size: 24px;
      cursor: pointer;
      color:$--color-text-primary;
      text-decoration:none;
      
    }
    a.community-text:hover {
      color:$--color-primary;
    }
    .community-carousel{
      margin-top: 20px;
    }
  }


}
</style>